import { Grid } from "../types/grids";

type GenericArrayItem = {
  0: Grid,
  1: number
}

export type SortOrder = 'asc' | 'desc' | undefined

export function stableSort (array: Grid[], cmp: (a: Grid, b: Grid) => number) {
  const stabilizedThis: GenericArrayItem[] = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  })
  return stabilizedThis.map(el => el[0]);
}

export function desc (a: any, b: any, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getSorting (order: SortOrder, orderBy: string) {
  return order === 'desc'
    ? (a: Grid, b: Grid) => desc(a, b, orderBy)
    : (a: Grid, b: Grid) => -desc(a, b, orderBy);
}

export const sortByField = (field: string) => (a: any, b: any) => {
  if (a[field] > b[field]) {
    return 1;
  } else if (a[field] < b[field]) {
    return -1;
  }
  return 0;
}
