import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Icons
// Project deps
import { Grid } from '../../types/grids';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    fontSize: '0.95rem',
    display: 'block',
  },
}));

type Props = {
  item: Grid,
}

const GridItem = (props: Props) => {
  const { item } = props;
  const classes = useStyles();
  return (
    <Paper style={{ padding: 8 }}>
      <Typography noWrap className={classes.tableCell}>
      <b>{`Area of use: `}</b>
        {`${item.area_of_use}`}
      </Typography>
      <Typography noWrap className={classes.tableCell}>
        <b>{`Boundary: `}</b>
        {`north: ${item.boundary.north}, west: ${item.boundary.west}, south: ${item.boundary.south}, east: ${item.boundary.east}`}
      </Typography>
      <Typography noWrap className={classes.tableCell}>
        <b>{`Source: `}</b>
        {`${item.source.country} / `}
        {`${item.source.name} `}
        {item.source.url && item.source.url !== '-' ? <a rel="noopener noreferrer" href={item.source.url} target='_blank'>(Source link)</a> : ''}
      </Typography>
      <Typography noWrap className={classes.tableCell}>
        <b>{`File: `}</b>
        <a rel="noopener noreferrer" href={item.file} target='_blank'>{item.file}</a>
      </Typography>
    </Paper>
  )
}

export default GridItem