import React from 'react';
import Draggable from 'react-draggable';
import { Paper } from '@material-ui/core';

function DraggablePaper (props: any) {
  const { id = 'draggable-dialog-title' } = props
  return (
    <Draggable
      handle={`#${id}`}
      defaultPosition={{ x: -260, y: -399 }}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

export default DraggablePaper