import React, { useEffect, useState, useRef, useCallback } from 'react';
import clsx from 'clsx';
import L from 'leaflet';
import moment from 'moment';
import { InputAdornment, AppBar, Toolbar, DialogContent, IconButton, Typography, Button, Dialog, DialogTitle, DialogActions, Table, TableBody, TableHead, TableRow, TableCell, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Icons
import ClearIcon from '@material-ui/icons/Close';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LocationIcon from '@material-ui/icons/LocationOn';
import SaveIcon from '@material-ui/icons/SaveAlt';
import SaveZipIcon from '@material-ui/icons/Archive';
import ArrowBottom from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
// Project deps
import config from './config';
import Map from './components/Map';
import API from './utils/api';
import withRoot from './withRoot'
import { GeotiffValuesMap, Grid } from './types/grids';
import { convertRawGrid, getSourceCrsString, getTargetCrsString } from './utils/grids';
import { inBounds } from './utils/pointInsidePolygon';
import { Column } from './components/GridsTable/utils'
import GridsTable from './components/GridsTable';
import DelayedSearchBox from './components/DelayedSearchBox';
import DraggablePaper from './components/DraggablePaper'
import GridItem from './components/GridItem';
import { CustomButton } from './components/CustomButton';
import { AppDrawer } from './components/Drawer';
import { drawerWidth } from './utils/constants';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: 0
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  searchInput: {
    paddingTop: '10.5px',
    paddingBottom: '10.5px',
  },
  highlighted: {
    borderBottom: '1px solid #fff',
    background: 'rgba(244,67,54,0.23)',
    '&&:hover': {
      backgroundColor: 'rgba(255,151,151,1)',
    }
  },
  tableCell: {
    fontSize: '0.95rem',
    display: 'block',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
}));

type Expanded = {
  [key: string]: boolean
}

const degreesToDMS = (degrees: number) => {
  const d = parseInt(degrees.toString())
  const m = parseInt(((degrees - d) * 60).toString())
  const s = ((degrees - d - (m / 60)) * 3600).toFixed(2)
  return `${d}° ${m}' ${s}"`
}

function App() {
  const classes = useStyles();
  const [search, setSearch] = useState<string>('');
  const [grids, setGrids] = useState<Grid[]>([]);
  const [trackedPosition, setTrackedPosition] = useState<L.LatLng | null>(null);
  const [selectedGridAgency, setSelectedGridAgency] = useState<{[key: string]: boolean}>({});
  const [selectedGridType, setSelectedGridType] = useState<{[key: string]: boolean}>({});
  const [selectedGridBounds, setSelectedGridBounds] = useState<L.LatLngBounds | null>(null);
  const [geotiffValues, setGeotiffValues] = useState<GeotiffValuesMap>({});
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [showGeotiffValues, setShowGeotiffValues] = useState<boolean>(false);
  const [selectedGridTypes, setSelectedGridTypes] = useState<string[]>([])
  const [expanded, setExpanded] = useState<Expanded>({});
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const displayAllAgencyRef = useRef(true);
  const displayAllTypeRef = useRef(true);
  let { current: displayAllAgency } = displayAllAgencyRef;
  let { current: displayAllType } = displayAllTypeRef;

  /**
   * Fetches the selected grid types from the API with debounce.
   * 
   * @param {string[]} types - The selected grid types to fetch.
   */
  const fetchSelectedGridTypes = useCallback(debounce((types) => {
    setLoading(true);
    API.getSelectedGridTypes(types).then((result: Grid[]) => {
      const convertedGrids = result.map(convertRawGrid);
      setGrids(convertedGrids);
      setLoading(false);
    });
  }, 700), []);

  useEffect(() => {
    fetchSelectedGridTypes(selectedGridTypes);
  }, [selectedGridTypes.length, fetchSelectedGridTypes]);

  const onChangeSearch = (value: string, name: string) => {
    setSearch(value);
  }
  const onClearSearch = (e: React.MouseEvent) => {
    setSearch('');
  }
  const onCloseDialog = () => {
    setShowGeotiffValues(false)
  }


  const onChangeUserPosition = (latLng: L.LatLng) => {
    setTrackedPosition(latLng);
  }

  const onRowClick = (event: React.MouseEvent) => {
    const { id } = event.currentTarget
    const [itemId, isExpanded] = id.split('-')
    setExpanded((prevState) => ({
      ...prevState,
      [itemId]: Boolean(parseInt(isExpanded))
    }))
  }

  const onSelectGridBounds = (grid: Grid) => (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    const bounds = L.latLngBounds(L.latLng(grid.boundary.north, grid.boundary.west), L.latLng(grid.boundary.south, grid.boundary.east));
    setSelectedGridBounds(bounds);
  }

  const filteredBySearchString = search
    ? grids.filter(grid => (grid.searchString || '')
        .toString()
        .match(new RegExp(search, 'i'))
      )
    : grids;
  const filteredGrids = filteredBySearchString
    .filter(grid =>
      (displayAllType || Boolean(selectedGridType[grid.type])) &&
      (displayAllAgency || Boolean(selectedGridAgency[grid.source.id]))
    );

  let highlightedGrids: Grid[] = [];
  if (trackedPosition) {
    highlightedGrids = filteredGrids.filter(grid => inBounds(trackedPosition, grid.bounds));
  }
  const highlightedString = highlightedGrids.map(grid => grid.name).join(',')
  const key = trackedPosition ? (highlightedString + '-' + trackedPosition.lat + ',' + trackedPosition.lng) : ''
  const geotiffValuesForPosition = geotiffValues[key] || []
  useEffect(() => {
    if (highlightedGrids.length > 0 && trackedPosition) {
      if (!showGeotiffValues) {
        setShowGeotiffValues(true)
      }
      setIsBusy(true)
      highlightedGrids
        .filter(grid => grid.file)
        .forEach(grid =>
          API.getGeotiffValue(grid, trackedPosition.lat, trackedPosition.lng, grid.file)
            .then(result => {
              setTimeout(() => {
                setGeotiffValues(prevState => ({
                  ...prevState,
                  [key]: [
                    ...(prevState[key] || []),
                    result
                  ],
                }))
              }, 100) 
            })
        )
    }
  }, [key])
  useEffect(() => {
    if (geotiffValuesForPosition.length === highlightedGrids.length) {
      setIsBusy(false)
    }
  }, [geotiffValuesForPosition.length])

  const columns: Column[] = [
    {
      id: 'name',
      label: 'Name',
      xs: 4,
      md: 4,
      render: (item: Grid) => (
        <Typography style={{ display: 'flex' }}>
          {expanded[item.id]
            ? <ArrowBottom/>
            : <ArrowRight/>
          }
          <Typography noWrap>
            <b>{item.name}</b>
            <Typography noWrap style={{ fontStyle: 'italic' }} className={classes.tableCell} component='span'>{item.description}</Typography>
          </Typography>
        </Typography>
      )
    },
    {
      id: 'agency',
      label: 'Agency',
      xs: 1,
      md: 1,
      render: item => <Typography className={classes.tableCell} noWrap component={'span'}>{item.source.id}</Typography>
    },
    {
      id: 'type',
      label: 'Type',
      xs: 1,
      md: 1,
      render: item => <Typography className={classes.tableCell} noWrap component={'span'}>{item.type}</Typography>
    },
    /*
    {
      id: 'description',
      label: 'Description',
    },
    */
    {
      id: 'crs', 
      label: 'CRS(source, target)',
      xs: 2,
      md: 2,
      render: item => (
        <React.Fragment>
          <Typography className={classes.tableCell} noWrap component={'span'}>{getSourceCrsString(item)}</Typography>
          <Typography className={classes.tableCell} noWrap component={'span'}>{getTargetCrsString(item)}</Typography>
        </React.Fragment>
      )
    },
    {
      id: 'updated',
      label: 'Last modified',
      xs: 1,
      md: 1,
      render: item => <Typography className={classes.tableCell} noWrap component={'span'}>{moment(item.updated).format(config.DATETIME_FORMAT)}</Typography>
    },
    {
      id: 'control',
      label: '',
      xs: 3,
      md: 3,
      render: item => (
        <div style={{ textAlign: 'right' }}>
            <CustomButton onClick={onSelectGridBounds(item)} startIcon={ <LocationIcon/>}>Locate</CustomButton>
            <CustomButton download={item.name} href={item.file} onClick={(e) => { e.stopPropagation() }} startIcon={<SaveIcon/>}>Download</CustomButton>
            <CustomButton download={item.name} href={`${config.API_BASE}download/?url=${item.file}`} onClick={(e) => { e.stopPropagation() }} startIcon={<SaveZipIcon/>}>Download ZIP</CustomButton>
        </div>
        )
    },
  ]
  const currentGeotiffValues = trackedPosition
    ? geotiffValues[highlightedString + '-' + trackedPosition.lat + ',' + trackedPosition.lng] || []
    : []
  return (
    <section style={{ height: '100%' }}>
      <Dialog
        style={{ position: 'sticky' }}
        PaperComponent={DraggablePaper}
        PaperProps={{
          style: {
            position: 'fixed',
            top: '50%',
          }
        }}
        open={showGeotiffValues}
        onClose={() => {}}
        BackdropComponent={undefined}
        hideBackdrop
        disableEnforceFocus
        // disablePortal
        disableBackdropClick
        disableAutoFocus
        disableScrollLock>
        <DialogTitle disableTypography id='draggable-dialog-title'>
          <Typography variant='h6' style={{ display: 'flex', alignItems: 'center' }}>
            Geotiff values
            <IconButton onClick={onCloseDialog} style={{ marginLeft: 'auto' }}><CloseIcon/></IconButton>
          </Typography>
          <div><b>(raw pixel value, not interpolated to coordinate)</b></div>
        </DialogTitle>
        <DialogContent>
          {trackedPosition &&
            <Table style={{ border: '1px solid #e0e0e0', marginBottom: 8 }}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Lat Y</TableCell>
                  <TableCell>Lon X</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Degrees</TableCell>
                  <TableCell>{trackedPosition.lat.toFixed(5)}</TableCell>
                  <TableCell>{trackedPosition.lng.toFixed(5)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>DMS</TableCell>
                  <TableCell>{degreesToDMS(trackedPosition.lat)}</TableCell>
                  <TableCell>{degreesToDMS(trackedPosition.lng)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          }
          {!isBusy && currentGeotiffValues.length <= 0 && <Typography variant='body2'>No geotiff values</Typography>}
          {(currentGeotiffValues.length > 0 || isBusy) && <Table style={{ border: '1px solid #e0e0e0', marginBottom: 8 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentGeotiffValues.map((value, index) => (
                <TableRow key={value.grid.name}>
                  <TableCell>{value.grid.name}</TableCell>
                  <TableCell>{value.data?.value.toFixed(5)} ({value.grid.units})</TableCell>
                  {/*<Typography><b>{value.grid.name}</b>: {value.data?.value.toFixed(5)}</Typography>*/}
                </TableRow>
              ))}
              {isBusy &&
                <TableRow key='loading'>
                  <TableCell colSpan={2}>
                    <Typography variant='body2'><CircularProgress size={14}/> Loading geotiff values...</Typography>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        }
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog} color='primary'>Ok</Button>
        </DialogActions>
      </Dialog>
      <AppBar position='sticky'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openDrawer,
        })}>
        <Toolbar classes={{ root: classes.toolbar}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, openDrawer && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Button style={{ marginLeft: 8 }} color='primary' variant='contained' disabled={showGeotiffValues} onClick={() => setShowGeotiffValues(true)}>
            Show Geotiff values
          </Button>
          <div style={{ marginLeft: 'auto' }}>
            <DelayedSearchBox
              value={search}
              onChange={onChangeSearch}
              otherProps={{
                style: { background: '#fff' },
                variant: 'outlined',
                color: 'secondary',
                placeholder: 'Search',
                InputProps: {
                  endAdornment: <InputAdornment position='end'>
                    <IconButton onClick={onClearSearch} style={{ padding: 4 }}>
                      <ClearIcon/>
                    </IconButton>
                  </InputAdornment>,
                  classes: {
                    input: classes.searchInput
                  }
                }
              }}
            />
          </div>
        </Toolbar>
      </AppBar>
      <AppDrawer
        open={openDrawer}
        handleDrawerClose={handleDrawerClose}
        selectedGridTypes={selectedGridTypes}
        setSelectedGridTypes={setSelectedGridTypes}
       />
      <Map
        onClick={onChangeUserPosition}
        grids={filteredGrids}
        highlighted={highlightedGrids}
        bounds={selectedGridBounds}
        isBusy={isBusy}
        boundsString={selectedGridBounds && selectedGridBounds.toBBoxString()}
      />
      <GridsTable
        grids={filteredGrids}
        highlighted={highlightedGrids}
        expanded={expanded}
        columns={columns}
        expandedRender={(item) => <GridItem item={item}/>}
        onRowClick={onRowClick}
        loading={loading}
        rowProps={(grid: Grid) => ({
          className: highlightedGrids.find(g => g.id === grid.id) ? classes.highlighted : '',
          // onClick: onRowClick(grid.id, !Boolean(expanded[grid.id])),
          // component: 'a',
          // href: grid.file,
          // download: grid.name,
        })}
      />
    </section>
  );
}

export default withRoot(App);
