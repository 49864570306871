// External dependencies.
import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import List from 'react-virtualized/dist/commonjs/List'
// Material UI dependencies.
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
// Project internal dependencies.
// import * as styles from "./style.css";

type RenderOptions = {
  index: number,
  isScrolling: boolean,
  key: string,
  style: Object,
}

type Props = {
  list: any[],
  autoSizeHeight?: boolean,
  // listWidth = 300,
  listHeight?: number,
  rowHeight: (item: any) => number,
  overscanRowCount?: number,
  showScrollingPlaceholder?: boolean,
  scrollingRender?: (item: any, options: RenderOptions) => void,
  renderItem: (item: any, options: RenderOptions) => any,
  noDataLabel?: string,
  id?: string,
}

const useStyles = makeStyles(theme => ({
  noRows: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#bdbdbd',
  },
  List: {
    width: '100%',
    border: '1px solid #DDD',
    // marginTop: theme.spacing(2),
  },
  row: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
  },
  isScrollingPlaceholder: {
    color: '#DDD',
    fontStyle: 'italic',
  }
}))

/**
 * Component that can display a large list of items without loosing the performance
 */
const VirtualizedList = (props: Props) => {
  const {
    list,
    autoSizeHeight = false,
    // listWidth = 300,
    listHeight = 300,
    rowHeight = () => 50,
    overscanRowCount = 1,
    showScrollingPlaceholder = false,
    scrollingRender,
    noDataLabel = 'No data',
    id,
  } = props
  const listRef = useRef(null)
  const classes = useStyles()
  useEffect(() => {
    if (listRef) {
      if (listRef.current !== undefined && listRef.current !== null && listRef.current) {
        // @ts-ignore: Object is possibly 'null'.
        listRef.current.recomputeRowHeights()
      }
    }
  }, [id])
  // const listWidth = props.listWidth || 300

  const __getRowHeight__ = () => ({ index }: { index: number }) => {
    const item = list[index % list.length]
    return rowHeight(item)
  }
  const getDatum = (index: number) => list[index % list.length]
  const noRowsRenderer = () => <Typography className={classes.noRows} style={{ padding: 8 }}>{noDataLabel}</Typography>

  const rowRenderer = (options: RenderOptions) => {
    const { index, isScrolling, key, style } = options
    const item = getDatum(index)
    if (showScrollingPlaceholder && isScrolling) {
      if (typeof scrollingRender === 'function') return scrollingRender(item, options)
      return (
        <div
          className={clsx(classes.row, classes.isScrollingPlaceholder)}
          key={key}
          style={style}>
          Scrolling...
        </div>
      )
    }
    return props.renderItem(item, options)
  }

  const style = autoSizeHeight ? { flex: '1 1 auto', height: '100%', width: '100%' } : { width: '100%', height: '100%' }

  return (
    <div style={style}>
      <AutoSizer disableHeight={!autoSizeHeight}>
        {({ width, height }) => (
          <List
            ref={listRef}
            className={classes.List}
            style={{ outline: 'none' }}
            height={autoSizeHeight ? height : listHeight}
            overscanRowCount={overscanRowCount}
            noRowsRenderer={noRowsRenderer}
            rowCount={list.length}
            rowHeight={__getRowHeight__()}
            rowRenderer={rowRenderer}
            width={width}
          />
        )}
      </AutoSizer>
    </div>
  )
}

VirtualizedList.propTypes = {
  noDataLabel: PropTypes.node,
  listWidth: PropTypes.number,
  rowHeight: PropTypes.number,
  listHeight: PropTypes.number,
  overscanRowCount: PropTypes.number,
  list: PropTypes.array,
  selectedData: PropTypes.array,
  autoSizeHeight: PropTypes.bool,
  showScrollingPlaceholder: PropTypes.bool,
  renderItem: PropTypes.func,
  scrollingRender: PropTypes.func,
}

export default VirtualizedList
