import axios from './axios';
import grids from '../templates/grids.json';
import { Grid } from '../types/grids';

export default {
  getGrids: async () => {
    try {
      const result = await axios.get('/grids');
      return result.data;
    } catch (e) {
      console.error('Error while getting /grids');
      return grids;
    }
  },
  getGeotiffValue: async (grid: Grid, lat: number, lon: number, geotiff_url: string) => {
    try {
      const result = await axios.get(`/geotif_value/?url=${geotiff_url}&lon=${lon}&lat=${lat}`);
      return { grid, data: result.data, okay: true };
    } catch (e) {
      console.error(`Error while getting geotiff value for ${geotiff_url}`);
      return { grid, data: null, okay: false };
    }
  },
  getGridTypes: async () => {
    try {
      const result = await axios.get('/grid_types');
      return result.data;
    } catch (e) {
      console.error('Error while getting grid types');
    }
  },
    getSelectedGridTypes: async (types: string[]) => {
      const queryParams = types.join(',')
    try {
      if (types.length < 1) {
        return []
      }
      const result = await axios.get(`/grids?types=${queryParams}`);
      return result.data;
    } catch (e) {
      console.error('Error while getting selected grid types');
    }
  }
}
