import L from 'leaflet';
import { Grid, GridCrsProperty } from "../types/grids";

export const convertRawGrid = (raw: Grid) => {
  const { name, source, crs, id, boundary, area_of_use, file, description, proj_cdn_url } = raw;
  return {
    ...raw,
    id: name,
    sourceCountry: source.country,
    source: {
      ...source,
      id: source.id === '-' ? 'custom' : source.id,
    },
    bounds: new L.LatLngBounds([boundary.south, boundary.east], [boundary.north, boundary.west]),
    searchString: id + ' '
      + crs.source.id + ' ' + crs.source.name + ' ' + crs.source.auth_name + ' ' + crs.source.code + ' '
      + crs.target.id + ' ' + crs.target.name + ' ' + crs.target.auth_name + ' ' + crs.target.code + ' '
      + source.name + ' ' + source.url + ' ' + source.country + source.id + ' '
      + boundary.south + ' ' + boundary.north + ' ' + boundary.west + ' ' + boundary.east + ' '
      + name + ' '
      + area_of_use + ' '
      + file + ' '
      + description + ' '
      + proj_cdn_url + ' '
  }
}

const getCrsString = (crs: GridCrsProperty) => {
  return [
    crs.name || '',
    crs.auth_name && crs.code
      ? crs.auth_name + ':' + crs.code
      : crs.auth_name || crs.code || '',
      crs.type || ''
  ]
  .filter(str => Boolean(str))
  .join(', ');
}

export function getSourceCrsString (grid: Grid) {
  const { crs } = grid;
  if (!crs.source.name && !crs.source.auth_name && !crs.source.code && !crs.source.type) return null;
  const { source } = crs;
  return getCrsString(source);
}
export function getTargetCrsString (grid: Grid) {
  const { crs } = grid;
  if (!crs.target.name && !crs.target.auth_name && !crs.target.code && !crs.target.type) return null;
  const { target } = crs;
  return getCrsString(target);
}