import axios from 'axios';
import config from '../config';

export default axios.create({
  baseURL: config.API_BASE,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})
