import React from 'react'
// Material UI
import { Button, ButtonProps } from '@material-ui/core'

interface Props extends ButtonProps {
  download?: string
}

export const CustomButton = (props: Props) => {
  const { children, ...rest } = props
  return (
    <Button size='small' variant="outlined" {...rest} style={{marginRight: 2, borderRadius: 4}}>
      {children}
    </Button>
  )
}
