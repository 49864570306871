import React, { useState } from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Tooltip,
  TableSortLabel,
  Grid as GridComponent,
  Typography,
} from '@material-ui/core';
// Icons
// Project deps
import { Grid } from '../../types/grids';
import { stableSort, getSorting, SortOrder } from '../../utils/sort';
import { Column } from './utils';
import VirtualizedList from '../VirtualizedList';
import { CustomLoader } from '../CustomLoader';

type Props = {
  grids: Grid[],
  highlighted: Grid[],
  expanded?: {
    [key: string]: boolean,
  },
  expandedRender?: (item: Grid) => React.ReactElement | string, 
  columns: Column[],
  loading: boolean,
  onRowClick: (event: React.MouseEvent) => void,
  rowProps: (item: Grid) => {
    className: string
  }
}

type SortData = {
  orderBy: string,
  order: SortOrder
}

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    borderBottom: '1px solid rgb(170,170,170,.2)',
    zIndex: theme.zIndex.drawer + 1,
  },
  bodyContainer: {
    borderBottom: '1px solid rgb(170,170,170,.2)',
    textDecoration: 'none',
    color: '#252525',
    padding: theme.spacing(0.5, 0.25),
    '&&:hover': {
      backgroundColor: 'rgb(170,170,170,.2)',
      cursor: 'pointer'
    }
  },
  tableRow: {
    '&&:hover + tr': {
      backgroundColor: 'rgb(170,170,170,.2)'
    }
  },
  tableCell: {
    padding: 0,
  }
}));

const GridsTable = (props: Props) => {
  const { grids, highlighted, columns, rowProps, expanded, expandedRender, onRowClick, loading } = props;
  const classes = useStyles();
  const [sortData, setSortData] = useState<SortData>({
    orderBy: 'updated',
    order: 'desc'
  });
  const { orderBy, order } = sortData;

  const onRequestSort = (event: React.MouseEvent, property: string) => {
    const orderByNew: string = property;
    let orderNew: SortOrder = 'desc';
    if (orderBy === property && order === 'desc') {
      orderNew = 'asc';
    }
    setSortData({
      order: orderNew,
      orderBy: orderByNew
    });
  };
  const createSortHandler = (property: string) => (event: React.MouseEvent) => onRequestSort(event, property);
  return (
    <section style={{ height: 'calc(100% - 55vh - 60px)', display: 'flex', flexDirection: 'column' }}>
      <GridComponent container component='header' className={classes.headerContainer}>
        {
          columns.map(({ label, id, md, xs }) => (
            <GridComponent
              xs={xs}
              md={md}
              item
              key={id}
            >
              <Tooltip
                title="Sort"
                placement={'bottom-start'}
                enterDelay={300}>
                <TableSortLabel
                  active={orderBy === id}
                  direction={order}
                  onClick={createSortHandler(id)}>
                  <b>{label}</b>
                </TableSortLabel>
              </Tooltip>
            </GridComponent>
          ))
        }
      </GridComponent>
      {loading
        ? <CustomLoader />
        : <main style={{ flex: 1 }}>
         <VirtualizedList
            autoSizeHeight
            rowHeight={(grid: any) => {
              const isExpanded = expanded && Boolean(expanded[grid.id])
              return isExpanded ? 170 : 60
            }}
            id={expanded
              ? Object.keys(expanded).length > 0 
                ? Object.keys(expanded).filter(key => expanded[key]).join(',')
                : 'no-expanded'
              : 'no-rerender'
            }
            list={
              stableSort(grids, getSorting(order, orderBy)).sort((a, b) => highlighted.find(grid => grid.id === a.id) ? -1 : 1)
            }
            renderItem={(grid, { key, style }) => {
              const isExpanded = expanded && Boolean(expanded[grid.id])
              return (
                <GridComponent
                  container
                  key={key}
                  classes={{ root: classes.bodyContainer }}
                  id={grid.id + '-' + (!isExpanded === false ? 0 : 1)}
                  onClick={onRowClick}
                  style={style}
                  {...(typeof rowProps === 'function' && rowProps(grid))}
                >
                  {
                    columns.map(({ xs, md, id, render }) => (
                      <GridComponent className={classes.tableCell} item xs={xs} md={md} key={id} noWrap component={Typography}>
                        {typeof render === 'function' ? render(grid) : grid[id]}
                      </GridComponent>
                    ))
                  }
                  <GridComponent item xs={12} style={{
                    display: isExpanded ? 'block' : 'none',
                    padding: isExpanded ? '4px' : '0px',
                  }}>
                    {expandedRender && expandedRender(grid)}
                  </GridComponent>
                </GridComponent>
              )
            }}
          />
      {/*
        grids.length > 0 
        ? stableSort(grids, getSorting(order, orderBy))
          .sort((a, b) => highlighted.find(grid => grid.id === a.id) ? -1 : 1)
          .map((grid: any) => {
            const isExpanded = expanded && Boolean(expanded[grid.id])
            return (
              <GridComponent
                container
                key={grid.id}
                classes={{ root: classes.bodyContainer }}
                id={grid.id + '-' + (!isExpanded === false ? 0 : 1)}
                onClick={onRowClick}
                {...(typeof rowProps === 'function' && rowProps(grid))}
              >
                {
                  columns.map(({ xs, md, id, render }) => (
                    <GridComponent className={classes.tableCell} item xs={xs} md={md} key={id} noWrap component={Typography}>
                      {typeof render === 'function' ? render(grid) : grid[id]}
                    </GridComponent>
                  ))
                }
                <GridComponent item xs={12} style={{
                  display: isExpanded ? 'block' : 'none',
                  padding: isExpanded ? '4px' : '0px',
                }}>
                  {expandedRender && expandedRender(grid)}
                </GridComponent>
              </GridComponent>
            )
          })
        : <GridComponent container>
            <GridComponent item xs={12} justify='center' container>No grids</GridComponent>
          </GridComponent>
        */}
      </main>
      }
    </section>
  )
}

export default GridsTable;

/*

    <Table>
      <TableHead>
        <TableRow>
          {
            HeaderOptions.map(({ label, id }) => (
              <TableCell
                key={id}
                sortDirection={orderBy === id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={'bottom-start'}
                  enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === id}
                    direction={order}
                    onClick={createSortHandler(id)}>
                    {label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ))
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          grids.length > 0
          ? stableSort(grids, getSorting(order, orderBy))
            .sort((a, b) => highlighted.find(grid => grid.id === a.id) ? -1 : 1)
            .map(grid => (
              <React.Fragment>
                <TableRow
                  key={grid.id}
                  className={classes.tableRow}
                  style={{ backgroundColor: highlighted.find(g => g.id === grid.id)
                    ? '#e77e7e'
                    : 'default'
                  }}
                  onClick={() => downloadGrid(grid.name, grid.file)}
                >
                  <TableCell style={{ borderBottom: 'none' }}>
                    <b>{grid.name}</b>
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>{grid.area_of_use}</TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>{moment(grid.updated).format(config.DATETIME_FORMAT)}</TableCell>
                </TableRow>
                <TableRow className={classes.tableRow}>
                  <TableCell colSpan={3}>
                    <i>{grid.description}</i>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))
          : <TableRow>
              <TableCell colSpan={3} align='center'>No grids</TableCell>
            </TableRow>
        }
      </TableBody>
    </Table>


    */