import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// Material UI
import { TextField, TextFieldProps } from '@material-ui/core'

type Props = {
  delay?: number,
  value: string,
  onChange: (value: string, name: string) => void,
  otherProps: TextFieldProps,
}

const DelayedSearchBox = (props: Props) => {
  const { onChange, value, delay = 500, otherProps } = props
  const [localValue, setLocalValue] = useState(value)
  const timerRef: any = useRef(null)

  useEffect(() => {
    setLocalValue(value)
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [value])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    if (!value) {
      onChange(value, name)
      setLocalValue(value)
    } else {
      timerRef.current = setTimeout(() => {
        onChange(value, name)
      }, delay)
      setLocalValue(value)
    }
  }

  return (
    <TextField
      {...otherProps}
      value={localValue}
      onChange={handleSearchChange}
    />
  )
}

DelayedSearchBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  delay: PropTypes.number,
}

export default DelayedSearchBox
