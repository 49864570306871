import React, { useState, useEffect } from 'react'
import {
  alpha,
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem'
import Collapse from '@material-ui/core/Collapse'
import { TransitionProps } from '@material-ui/core/transitions'
import CheckboxIcon from '@material-ui/icons/CheckBox'
import CheckboxEmptyIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import API from '../../utils/api'
import { CustomLoader } from '../CustomLoader'

type GridTypes = {
  [key: string]: string[]
}

type Props = {
  selectedGridTypes: string[]
  setSelectedGridTypes: (selected: string[]) => void
}

function TransitionComponent(props: TransitionProps) {
  return <Collapse {...props} />
}

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  })
)((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 264,
      flexGrow: 1,
      maxWidth: 420,
    },
  })
)

export const CustomTreeView = (props: Props) => {
  const { selectedGridTypes, setSelectedGridTypes } = props
  const classes = useStyles()
  const [gridTypes, setGridTypes] = useState<GridTypes>({})
  const [selectedGridTypesCategory, setSelectedGridTypesCategory] = useState<string[]>([])
  const [expanded, setExpanded] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    API.getGridTypes().then((data) => {
      setGridTypes(data)
      setExpanded(Object.keys(data))
      setLoading(false)
    })
  }, [])

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    event.preventDefault()
    event.stopPropagation()
    setExpanded(nodeIds)
  }

  /**
   * Handles the click event on an icon to select/deselect grid types and categories.
   *
   * @param {React.MouseEvent} event - The mouse event triggered by clicking the icon.
   * @param {string} nodeId - The ID of the clicked node.
   */
  const handleIconClick = (event: React.MouseEvent, nodeId: string) => {
    event.stopPropagation()
    const newSelected = new Set(selectedGridTypes)
    const newSelectedCategory = new Set(selectedGridTypesCategory)

    if (gridTypes[nodeId]) {
      // Category node: select/deselect all subcategories
      if (newSelectedCategory.has(nodeId)) {
        gridTypes[nodeId].forEach((childId) => newSelected.delete(childId))
        newSelectedCategory.delete(nodeId)
      } else {
        gridTypes[nodeId].forEach((childId) => newSelected.add(childId))
        newSelectedCategory.add(nodeId)
      }
    } else {
      // Subcategory node: select/deselect individually
      if (newSelected.has(nodeId)) {
        newSelected.delete(nodeId)
      } else {
        newSelected.add(nodeId)
      }
    }

    setSelectedGridTypes(Array.from(newSelected))
    setSelectedGridTypesCategory(Array.from(newSelectedCategory))
  }

  /**
   * Renders the appropriate checkbox icon based on the selection state of the node.
   *
   * @param {string} nodeId - The ID of the node to determine its selection state.
   * @returns {JSX.Element} - A JSX element representing the checkbox icon.
   */
  const renderCheckboxIcon = (nodeId: string) => {
    return selectedGridTypes.includes(nodeId) ||
      selectedGridTypesCategory.includes(nodeId) ? (
      <CheckboxIcon color='error' />
    ) : (
      <CheckboxEmptyIcon />
    )
  }

  /**
   * Renders the label for a tree item, including a checkbox icon and the item key.
   *
   * @param {string} key - The key of the tree item to render.
   * @returns {JSX.Element} - A JSX element representing the tree item label with a checkbox icon.
   */
  const renderTreeItemLabel = (key: string) => {
    return (
      <div style={{ display: 'flex', marginLeft: -27 }}>
        <div
          onClick={(event) => handleIconClick(event, key)}
          style={{ marginRight: 5, cursor: 'pointer' }}
        >
          {renderCheckboxIcon(key)}
        </div>
        {key}
      </div>
    )
  }

  if (loading) return <CustomLoader />

  return (
    <TreeView
      className={classes.root}
      multiSelect={true}
      selected={selectedGridTypes}
      expanded={expanded}
      onNodeToggle={handleToggle}
      disableSelection={true}
    >
      {Object.keys(gridTypes).map((key: string) => {
        return (
          <StyledTreeItem nodeId={key} label={renderTreeItemLabel(key)}>
            {gridTypes[key].map((item: string) => {
              return (
                <StyledTreeItem
                  nodeId={item}
                  label={renderTreeItemLabel(item)}
                />
              )
            })}
          </StyledTreeItem>
        )
      })}
    </TreeView>
  )
}
