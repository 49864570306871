import React from 'react'
import { CircularProgress } from '@material-ui/core'

export const CustomLoader = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CircularProgress />
    </div>
  )
}
