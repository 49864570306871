import { LatLng, Polygon, polygon, LatLngBounds } from 'leaflet'

export const clampDegrees = (deg: number) => {
  return deg > 360 ? deg - 360 : deg < 0 ? 360 + deg : deg;
}

type Bounds = {
  west: number,
  east: number,
  south: number,
  north: number,
}

export const isPointInBounds = (point: LatLng, bounds: Bounds) => {
  const eb = bounds.east;  // clampDegrees(bounds.east)
  const wb = bounds.west;  // clampDegrees(bounds.west)
  const nb = bounds.north; // clampDegrees(bounds.north)
  const sb = bounds.south; // clampDegrees(bounds.south)

  /*
  const pt = turf.point([position.lng, position.lat])
  const poly = turf.polygon([[

    [wb, nb],
    [eb, nb],
    [eb, sb],
    [wb, sb],
    [wb, nb],
  ]])
  turf.booleanPointInPolygon(pt, poly)
  */
  return inBounds(point, new LatLngBounds([sb, eb], [nb, wb]))
  return isPointInsidePolygon(point, polygon(
    [
      [nb, wb],
      [nb, eb],
      [sb, eb],
      [sb, wb],
    ]
  ));
}

function isPointInsidePolygon (point: LatLng, polygon: L.Polygon) {
  let inside = false;
  const x = point.lat;
  const y = point.lng;
  for (let ii = 0; ii < polygon.getLatLngs().length; ii++) {
    const polyPoints: any = polygon.getLatLngs()[ii];
    for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      const xi = polyPoints[i].lat; const yi = polyPoints[i].lng;
      const xj = polyPoints[j].lat; const yj = polyPoints[j].lng;

      const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
  }
  return inside;
}

export const inBounds = function (point: LatLng, bounds: LatLngBounds) {
  let lng;
  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();
  let pointLng = point.lng;
  if (northEast.lng > 180 || southWest.lng > 180) {
    pointLng = clampDegrees(point.lng);
  }
  const multLng = (pointLng - northEast.lng) * (pointLng - southWest.lng);
  if (northEast.lng > southWest.lng) {
      lng = multLng < 0;
  } else {
      lng = multLng > 0;
  }

  const lat = (point.lat - northEast.lat) * (point.lat - southWest.lat) < 0;
  return lng && lat;
};