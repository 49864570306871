import React from 'react'
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { CustomTreeView } from '../TreeView'
import { drawerWidth } from '../../utils/constants'

type Props = {
  open: boolean
  handleDrawerClose: () => void
  selectedGridTypes: string[]
  setSelectedGridTypes: (selected: string[]) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#fafafa',
      height: 'calc(55vh + 48px)',
    },
    drawerContainer: {
      height: '100%',
      padding: '0 10px',
      marginTop: '-40px',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    divider: {
      background: 'rgb(170,170,170,.4)',
    }
  })
)

/**
 * AppDrawer component provides a drawer on the left side of the screen.
 * It includes a custom tree view component for selecting grid types.
 *
 * @param {Props} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered AppDrawer component.
 */
export const AppDrawer = (props: Props) => {
  const { open, handleDrawerClose, selectedGridTypes, setSelectedGridTypes } = props
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider variant="middle" classes={{root: classes.divider}} />
        <Toolbar />
        <div className={classes.drawerContainer}>
          <CustomTreeView
            selectedGridTypes={selectedGridTypes}
            setSelectedGridTypes={setSelectedGridTypes}
          />
        </div>
      </Drawer>
    </div>
  )
}
