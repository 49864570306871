import React from 'react';
// import { create } from 'jss'
// Material UI
import {
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import StylesProvider from '@material-ui/styles/StylesProvider';
import createGenerateClassName from '@material-ui/styles/createGenerateClassName';
import CssBaseline from '@material-ui/core/CssBaseline';
// Local deps

export let theme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'dashboard', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      // dashboard: 1050,
      lg: 1080, // 1280
      xl: 1920,
    },
  },
  typography: {
    h1: {
      fontWeight: 300,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    header: '#262b30',
    navigator: {
      light: '#3e3e3e',
      main: '#222',
      dark: '#262b30',
    },
    text: {
      // primary: '#d3d3d3',
      // secondary: '#00ff00'
    },
    primary: {
      light: '#f4bab6', // '#f4b7b3', // '#C5CAE9',
      main: '#f44336', // '#3F51B5',// '#d10404',// '#FF4081',// '#3F51B5',
      dark: '#de392d', // '#3c3c3c', // '#303F9F',
      darker: '#c62d22', // '#232347',
      darkest: '#200806', // '#080820',
      alternative: '#fd685d',
    },
    secondary: {
      light: '#ffe4e2', // '#F8BBD0',
      main: '#f44336', // '#FF4081', // '#d10404',
      dark: '#a72f27', // '#C2185B',
      alternative: '#3d4151',
      alternativeLight: '#50566d',
    },
    tertiary: {
      light: '#B2DFDB',
      main: '#009688',
      dark: '#00796B',
    },
    alternative: {
      main: '#31ba6b',
      dark: '#249052',
    },
    grey: {
      light: '#F5F5F5',
      darkLight: '#c3c2c2', // '#EAEAEA',
      main: '#9E9E9E',
      dark: '#616161',
      darker: '#212121',
      alternative: '#b6b6b6',
    },
    /*
    artifactStatusColor: {
      [ArtifactStatuses.PROCESSING]: '#5e5ef5',

      [ArtifactStatuses.READY]: '#349334',

      [ArtifactStatuses.OUTPUT_OF_FAILED_PIPELINE]: '#f44336',
      [ArtifactStatuses.UNCOMPLETED]: '#f44336',

      [ArtifactStatuses.UPLOADING]: '#ffb328',

      [ArtifactStatuses.SCHEDULED]: '#a5a5a5',
      [ArtifactStatuses.WAITING]: '#a5a5a5',
    },
    */
  },
  shape: {
    borderRadius: 1,
  },
})

theme = {
  ...theme,
  global: {
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.4em',
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': `inset 0 0 6px ${theme.palette.primary.main}`,
        borderRadius: 1,
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        outline: `1px solid ${theme.palette.primary.dark}`,
      },
    },
  },
  overrides: {
    MuiInputBase: {
      focused: {
        color: '#000 !important',
      },
    },
    MuiTypography: {
      /*
      body1: {
        fontSize: '0.875rem'
      },
      body2: {
        fontSize: '1rem'
      }
      */
    },
    MuiSwitch: {
      track: {
        backgroundColor: theme.palette.text.primary,
      },
    },
    MuiTableRow: {
      root: {
        // minHeight: `${theme.spacing(6)}px !important`,
        verticalAlign: 'middle',
        '&&:hover': {
          backgroundColor: 'rgb(170,170,170,.2)',
          cursor: 'pointer',
        },
      },
      head: {
        height: theme.spacing(6),
      },
      selected: {
        backgroundColor: 'rgba(92, 92, 92, 0.07)',
      },
    },
    MuiFab: {
      root: {
        zIndex: 10,
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: 0,
      },
    },
    MuiListItem: {
      root: {
      },
      selected: {
        // backgroundColor: `${theme.palette.primary.dark} !important`
        /*
        '&&': {
          backgroundColor: `${theme.palette.primary.dark}`,
          color: theme.palette.grey.light,
        },
        '&&:hover': {
          backgroundColor: `${theme.palette.primary.dark}`,
        },
        */
      },
    },
    MuiGrid: {
      container: {
        padding: 5,
      },
    },
    MuiTable: {
      root: {
        width: '100%',
      },
    },
    MuiPrivateTextarea: {
      root: {
        height: '100%',
      },
    },
    MuiTableCell: {
      root: {
        textAlign: 'left',
        padding: 4,
        paddingLeft: theme.spacing(1),
        // minWidth: 32,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        verticalAlign: 'middle',
        // minHeight: `${theme.spacing(6)}px !important`,
      },
      sizeSmall: {
        padding: 2,
        paddingLeft: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
          padding: 0,
          paddingLeft: theme.spacing(0.5),
        },
      },
      head: {
        verticalAlign: 'middle',
      },
      body: {
        position: 'relative',
      },
      paddingCheckbox: {
        width: 55,
      },
    },
    MUIDataTable: {
      /*
      paper: {
        background: '#fff',
      },
      */
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: 'inherit',
      },
    },
    MUIDataTableBodyRow: {
      root: {
        height: '48px',
      },
      responsiveStacked: {
        [theme.breakpoints.down('sm')]: {
          height: '48px !important',
          width: '50% !important',
        },
      },
    },
    MUIDataTableBodyCell: {
      responsiveStacked: {
        padding: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
          height: '48px !important',
          width: '50% !important',
        },
      },
      cellStacked: {
        background: 'inherit',
        padding: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
          height: '48px !important',
          width: '50% !important',
        },
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: theme.palette.primary.darkest,
      },
    },
    MuiButton: {
      containedSecondary: {
        backgroundColor: theme.palette.secondary.alternative,
        '&&:hover': {
          backgroundColor: theme.palette.secondary.alternativeLight,
        },
      },
      textSecondary: {
        // backgroundColor: 'rgb(231,3,84,.2)',
      },
      label: {
        textTransform: 'initial',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiFormLabel: {
      focused: {
        color: theme.palette.primary.main,

      },
    },
    MuiTabs: {
      indicator: {
        height: 2,
        // borderTopLeftRadius: 3,
        //  borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiDialogTitle: {
      root: {
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: theme.spacing(0, 2),
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: 0,
        padding: `0 12px`,
        minWidth: '0 !important',
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
          minWidth: '0 !important',
        },
        '&:hover': {
          color: '#fff',
        },
      },
      fullWidth: {
        flexBasis: 'auto',
      },
      selected: {
        // backgroundColor: `rgba(0,0,0,0) !important`,
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
      light: {
        backgroundColor: theme.palette.text.primary,
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
      root: {
        // padding: theme.spacing(0, 2),
        margin: '0 !important',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '0 !important',
        color: 'inherit',
        marginRight: 8,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: theme.palette.secondary.alternative,
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiPaper: {
      root: {
        borderRadius: 0,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
    text: {
      marginLeft: theme.spacing(0.5),
    },
    failedItem: {
      backgroundColor: '#ffd8d8 !important',
      '&&:hover': {
        backgroundColor: '#f9b1b1 !important',
      },
    },
  },
}

// Create a JSS instance with the default preset of plugins.
// It's optional.
// const jss = create(jssPreset())

// The standard class name generator.
// It's optional.
const generateClassName = createGenerateClassName();

function withRoot (Component) {
  function WithRoot (props) {
    // JssProvider allows customizing the JSS styling solution.
    return (
      <StylesProvider generateClassName={generateClassName}>
        {/* MuiThemeProvider makes the theme available down the React tree
          thanks to React context. */}
        <MuiThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...props} />
        </MuiThemeProvider>
      </StylesProvider>
    )
  }

  return WithRoot
}

export default withRoot
