
const location:Location = window.location
export default {
  API_BASE: location.origin.includes('grids.phoenixlidar.com')
    ? 'https://grids.phoenixlidar.com/api/v2/'
    : location.origin.includes('geoids.phoenixlidar.com')
    ? 'https://geoids.phoenixlidar.com/api/v2/'
    : process.env.NODE_ENV === 'development' 
    ? 'https://grids.phoenixlidar.com/api/v2/'
    : `${location.origin}/api/v2/`,
  DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss'
}