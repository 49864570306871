import React, { useState, useRef, useEffect } from 'react';
import L from 'leaflet';
import DeckGL from '@deck.gl/react';
import { MapView, RGBAColor, WebMercatorViewport } from '@deck.gl/core';
import { BitmapLayer, GeoJsonLayer, TextLayer, ScatterplotLayer } from '@deck.gl/layers';
import { TileLayer } from '@deck.gl/geo-layers';
// Project deps
import { Grid } from '../../types/grids';
import API from '../../utils/api';
import 'mapbox-gl/dist/mapbox-gl.css';

type Props = {
  grids: Grid[],
  onClick: (e: L.LatLng) => void,
  highlighted: Grid[],
  bounds: L.LatLngBounds | null,
  boundsString: string | null,
  isBusy: boolean,
}

const configs = [
  {
    url: 'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
    name: 'OpenStreetMap',
    checked: true,
    attribution: '© <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors.'
  },
  /*
  googleSatellite: {
    url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    subdomains: ['mt0','mt1','mt2','mt3']
  },
  */
 /*
  {
    name: 'Satellite',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community',
  },
  */
];


const highlightFillColor: RGBAColor = [255, 0, 0, 50]
const fillColor: RGBAColor = [0, 0, 0, 10]
const hightlightStrokeColor: RGBAColor = [255, 0, 0, 100]
const strokeColor: RGBAColor = [0, 0, 0, 100]
const tileLayers = configs.map(config => new TileLayer({
    id: config.name,
    data: config.url,
    minZoom: 0,
    maxZoom: 19,
    tileSize: 256,
    renderSubLayers: (props: any) => {
      const {
        bbox: {west, south, east, north}
      } = props.tile;
      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north]
      });
    },
}))

const views = [
  new MapView({})
]
const Map = (props: Props) => {
  const { grids, onClick, highlighted, bounds, boundsString } = props;
  const [trackedPosition, setTrackedPosition] = useState<L.LatLng | null>(null);
  const mapRef = useRef<any>(null);
  const [viewState, setViewState] = useState<any>({
    longitude: 0,
    latitude: 0,
    zoom: 1,
    pitch: 0,
    bearing: 0,
    altitude: 1000,
  })

  useEffect(() => {
    if (mapRef.current && boundsString) {
      const deck = mapRef.current.deck
      const viewport = new WebMercatorViewport({ ...viewState, width: 400, height: 600 })
      if (viewport && bounds) {
        const sw = bounds.getSouthWest()
        const ne = bounds.getNorthEast()
        const { longitude, latitude, zoom }: any = viewport.fitBounds([
          [sw.lng, sw.lat],
          [ne.lng, ne.lat]
        ]);
        // Zoom to the object
        const newViewState = {
          ...viewState,
          longitude,
          latitude,
          zoom,
        }
        setViewState(newViewState)
        deck.setProps({ initialViewState: newViewState })
      }
    }
  }, [boundsString])

  const onHandleClick = (info: any, event: any) => {
    const lng = info.coordinate[0];
    if (lng < -180 || lng > 180) {
      return;
    }
    const latLng: L.LatLng = new L.LatLng(info.coordinate[1], info.coordinate[0]);
    setTrackedPosition(latLng);
    onClick(latLng);
  }

  const polygons: any = grids.map(grid => {
    const isHighlighted = highlighted.find(g => g.id === grid.id);
    return {
      ...L.rectangle(grid.bounds).toGeoJSON(),
      properties: {
        isHighlighted,
        grid,
      }
    }
  })
  return <div style={{ height: '55vh', position: 'relative' }}>
    <DeckGL
      ref={mapRef}
      width="100%"
      height="100%"
      getTooltip={(event: any) => {
        if (event.object) {
          if (event.object.properties && event.object.properties.grid) {
            const grid = event.object.properties.grid
            return {
              html: `<h2 style="margin: 0">${grid.name}</h2><div>${grid.description}</div>`,
              style: {
                padding: 2,
                fontSize: '0.75em'
              }
            }
          }
        } else if (event.coordinate) {
          return {
            text: `lat lng (${event.coordinate[1].toFixed(5)}, ${event.coordinate[0].toFixed(5)})`,
            style: {
              padding: 2,
              fontSize: '0.75em'
            }
          }
        }
        return null
      }}
      views={views}
      onClick={onHandleClick}
      controller={true}
      layers={[
        ...tileLayers,
        new GeoJsonLayer({
          id: 'geojson-layer',
          wrapLongitude: true,
          autoHighlight: true,
          highlightColor: highlightFillColor,
          data: {
            "type": "FeatureCollection",
            "features": polygons
          },
          pickable: true,
          filled: true,
          stroked: true,
          getFillColor: (feature: any): RGBAColor => {
            if (feature.properties.isHighlighted || feature.properties.isTracked) {
              return highlightFillColor
            }
            return fillColor
          },
          getLineColor: (feature: any): RGBAColor => {
            if (feature.properties.isHighlighted || feature.properties.isTracked) {
              return hightlightStrokeColor
            }
            return strokeColor
          },
          getLineWidth: 1,
          lineWidthMinPixels: 1,
        }),
        new ScatterplotLayer({
          id: 'ScatterplotLayer',
          visible: !!trackedPosition,
          data: [{
            name: '',
            coordinates: [trackedPosition?.lng, trackedPosition?.lat]
          }],
          pickable: false,
          opacity: 0.8,
          // stroked: true,
          filled: true,
          radiusScale: 1,
          radiusUnits: 'pixels',
          radiusMinPixels: 1,
          radiusMaxPixels: 100,
          lineWidthMinPixels: 1,
          getPosition: (d: any) => d.coordinates,
          getRadius: (d: any) => 5,
          // getFillColor: (d: any) => [255, 140, 0],
          // getLineColor: (d: any) => [0, 0, 0]
          
        }),
      ]}
      // viewState={viewState}
      initialViewState={viewState}
    >
    </DeckGL>
  </div>
}

export default Map;